import React from 'react';
import Footer from './common/footer';
import Navigation from './common/navigation';

const ContactUsPage = () => {
    return (
        <>
            <Navigation />

            <div className="container mt-5 mb-5" style={{ color: 'black' }} >
                <h2>Contact Us</h2>
                <form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Your Name</label>
                        <input type="text" className="form-control" id="name" placeholder="Enter your name" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address</label>
                        <input type="email" className="form-control" id="email" placeholder="name@example.com" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea className="form-control" id="message" rows="5" placeholder="Enter your message"></textarea>
                    </div>
                    <div className="text-right">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>

            <Footer />
        </>


    );
};

export default ContactUsPage;
