import React from 'react';

import TechieBuddyAIComponent from './products/techie-buddy-ai-component';
import HttpLogViwerComponent from './products/httplogviewer-component';
import TgVpnBotComponent from './products/tgvpnbot-component';
import PsmComponent from './products/psm-component';
import TgBotPlateComponent from './products/tgbotplate-component';
import EncryptorCComponent from './products/encryptor-component';
import GoodLuckComponent from './products/goodluck-component';
import PrintmanComponent from './products/prinman-component';

import Lead from './common/lead';
import Footer from './common/footer';
import Navigation from './common/navigation';
import ProductList from './common/ProductList';

function HomePage() {
    return (
        <>
            <Navigation />
            <Lead />

            <ProductList />

            <TechieBuddyAIComponent />
            <EncryptorCComponent />
            <HttpLogViwerComponent />
            <TgVpnBotComponent />
            <PsmComponent />
            <TgBotPlateComponent />
            <GoodLuckComponent />
            <PrintmanComponent />

            <Footer />

        </>
    );
}

export default HomePage;
