import React from 'react';
import CryptorC from '../compoonents/cryptor.js';

function CryptorPage() {
    return (
        <>
            <CryptorC />
        </>
    );
}

export default CryptorPage;
