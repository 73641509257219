import ui1 from '../../images/promo/ui1.png';
import ui2 from '../../images/promo/ui2.png';
import encqr from '../../images/promo/encqr.jpg';

const EncryptorCComponent = () => {
    return (
        <>

            <div class="row text-center" style={{ paddingTop: "100px", color: "black" }} id="cryptorc">
                <div class="col-12">
                    <h1 class="display-4 mb-4"><b style={{ backgroundColor: "#1e90ff", padding: "10px 15px", color: "white" }}>Encryptor C</b> is a client-side encryption and decryption tool</h1>
                </div>
            </div>

            <div className="row text-left" style={{ padding: "15px 0px 50px 0px", color: "black" }}>
                <div className="container">
                    <hr />
                    <p className="lead mb-4">
                        <b>Encryptor C</b> is a client-side encryption and decryption tool built with ReactJS and Bootstrap. It enables users to encrypt text and files using AES-GCM encryption with a password, and decrypt them locally without sending any data to a server.
                    </p>

                    <div className="text-right">
                        <a className="btn btn-primary mt-3 mr-3" href="https://t.me/encryptor_c_bot" role="button" target="_blank">
                            <i class="fa-brands fa-telegram"></i> Bot
                        </a>


                        <a className="btn btn-primary mt-3 mr-3" href="/cryptor" role="button" target="_blank">
                            &nbsp; Web Version
                        </a>

                        <a className="btn btn-info mt-3" href="https://github.com/anton-salata/reactjs-encryptor-component" role="button" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z" />
                            </svg>
                            &nbsp; GitHub
                        </a>
                    </div>
                    <hr />
                </div>
            </div>



            <div id="myCarousel2" className="carousel slide pb-5 carousel-dark" data-ride="carousel" style={{ color: 'black' }}>
                <ol className="carousel-indicators">
                    <li data-target="#myCarousel2" data-slide-to="0" className="active invert"></li>
                    <li data-target="#myCarousel2" data-slide-to="1" className="invert"></li>
                    <li data-target="#myCarousel2" data-slide-to="2" className="invert"></li>
                </ol>

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row ">
                            <div className="container">
                                <div class="row mb-5">
                                    <div class="col-md-7 lead">

                                        <h3>Features</h3>
                                        <ul>
                                            <li>Encrypt text and files (including images).</li>
                                            <li>Decrypt encrypted data using the password.</li>
                                            <li>Supports uploading multiple files with captions.</li>
                                            <li>Outputs encrypted data as a Base64 string.</li>
                                        </ul>
                                        <h3>Security</h3>
                                        <p>Cryptor C operates entirely on the client side. No data is transmitted to a server, ensuring user privacy and security.</p>

                                    </div>
                                    <div class="col-md-5">
                                        <img src={ui1} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row ">
                            <div className="container">
                                <div class="row mb-5">
                                    <hr></hr>
                                    <div class="col-md-4 lead">
                                        <img src={ui2} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                                    </div>
                                    <div class="col-md-8 lead">
                                        <ol>
                                            <li><strong>Encrypt Tab</strong>:
                                                <ul>
                                                    <li>Enter text to encrypt in the textarea.</li>
                                                    <li>Upload files (images or other types).</li>
                                                    <li>Set a password for encryption.</li>
                                                    <li>Click the "Encrypt" button to encrypt the data.</li>
                                                </ul>
                                            </li>
                                            <li><strong>Decrypt Tab</strong>:
                                                <ul>
                                                    <li>Paste or enter the encrypted data.</li>
                                                    <li>Enter the password used for encryption.</li>
                                                    <li>Click the "Decrypt" button to view decrypted text and download files.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                    <div className="carousel-item">
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-12">
                                <div className="card mx-auto" style={{ padding:"50px" }}>
                                    <img src={encqr} className="card-image" alt="Card" style={{ width: "240px", margin: "10px auto" }} />
                                    <div className="card-content text-center">
                                        <h2 className="card-title">
                                            <p>Available via <i className="fa-brands fa-telegram"></i> Telegram Bot</p>
                                        </h2>
                                        <p>Encryption/Decryption client side tool</p>
                                        <a href="https://t.me/encryptor_c_bot" className="card-link" target="_blank" rel="noopener noreferrer">@encryptor_c_bot</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a className="carousel-control-prev" href="#myCarousel2" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon invert" aria-hidden="true" style={{ filter: "invert(100%)" }}></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#myCarousel2" role="button" data-slide="next">
                    <span className="carousel-control-next-icon invert" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>


        </>
    );
}

export default EncryptorCComponent;