import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Ticker.css';

const Ticker = () => {
    const [cryptoData, setCryptoData] = useState([]);
    const [weatherData, setWeatherData] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
  const quotes = [
    "The only way to do great work is to love what you do. - Steve Jobs",
    "Success is not the key to happiness. Happiness is the key to success. - Albert Schweitzer",
    "Your time is limited, so don't waste it living someone else's life. - Steve Jobs",
    "In the middle of difficulty lies opportunity. - Albert Einstein",
    "Believe you can and you're halfway there. - Theodore Roosevelt",
    "Quality is not an act, it is a habit. - Aristotle",
    "The best way to predict the future is to create it. - Abraham Lincoln",
    "Stay hungry, stay foolish. - Steve Jobs",
    "It does not matter how slowly you go as long as you do not stop. - Confucius",
    "The only limit to our realization of tomorrow will be our doubts of today. - Franklin D. Roosevelt",
    "The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
    "Strive not to be a success, but rather to be of value. - Albert Einstein",
    "You miss 100% of the shots you don't take. - Wayne Gretzky",
    "Don't watch the clock; do what it does. Keep going. - Sam Levenson",
    "The journey of a thousand miles begins with one step. - Lao Tzu",
    "You must be the change you wish to see in the world. - Mahatma Gandhi",
    "A person who never made a mistake never tried anything new. - Albert Einstein",
    "Life is what happens when you're busy making other plans. - John Lennon",
    "Success usually comes to those who are too busy to be looking for it. - Henry David Thoreau",
    "Opportunities don't happen, you create them. - Chris Grosser",
    "The best revenge is massive success. - Frank Sinatra",
    "Don't cry because it's over, smile because it happened. - Dr. Seuss",
    "I have not failed. I've just found 10,000 ways that won't work. - Thomas A. Edison",
    "If you want to lift yourself up, lift up someone else. - Booker T. Washington",
    "The mind is everything. What you think you become. - Buddha",
    "It always seems impossible until it is done. - Nelson Mandela",
    "The only limit to our realization of tomorrow will be our doubts of today. - Franklin D. Roosevelt",
    "We can't help everyone, but everyone can help someone. - Ronald Reagan",
    "Do not wait to strike till the iron is hot, but make it hot by striking. - William Butler Yeats"
];

    useEffect(() => {
        const fetchCryptoData = async () => {
            const result = await axios('https://api.coingecko.com/api/v3/coins/markets', {
                params: {
                    vs_currency: 'usd',
                    order: 'market_cap_desc',
                    per_page: 5,
                    page: 1,
                    sparkline: false
                }
            });
            setCryptoData(result.data);
        };


        const fetchWeatherData = async () => {
            try {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const result = await axios.get('https://api.openweathermap.org/data/2.5/weather', {
                        params: {
                            lat: position.coords.latitude,
                            lon: position.coords.longitude,
                            appid: '50b1f2ac6e8d75884f0ce79d2868a60d',
                            units: 'metric'
                        }
                    });
                    setWeatherData(result.data);
                });
            } catch (error) {
                console.error('Error fetching weather data:', error.message);
                // Handle error state or show error message to user
            }
        };

        fetchCryptoData();
        fetchWeatherData();
    }, []);

    const renderTickerContent = () => {
        const cryptoContent = cryptoData.map((coin, index) => (
            <span key={coin.id}>
                <img src={coin.image} alt={coin.name} className="coin-image" />
                {coin.name}: ${coin.current_price}
                {index !== cryptoData.length - 1 && ' | '}
            </span>
        ));
        const weatherContent = weatherData ? (
            <span>
                Weather in {weatherData.name}: {weatherData.weather[0].description}, {weatherData.main.temp}°C
            </span>
        ) : (
            <span>Fetching weather...</span>
        );
        const quotesContent = <span><b>{quotes[Math.floor(Math.random() * quotes.length)]}</b></span>;

        return (
            <>
                {cryptoContent}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {weatherContent}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {quotesContent}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </>
        );
    };

    return (
        <div className={`ticker-container ${isPaused ? 'paused' : ''}`}>
            <div className="ticker-content">
                {renderTickerContent()}
            </div>
            <div className="ticker-content">
                {renderTickerContent()}
            </div>
            <div
                className="ticker-button"
                onClick={() => setIsPaused(!isPaused)}
                style={{ cursor: 'pointer' }}
            >
                {isPaused ? '▶' : '❚❚'}
            </div>
        </div>
    );
};

export default Ticker;
