import PersonalMainImg from '../../images/promo/Depl.png';
import PersonalServerImg from '../../images/promo/serverscripts.png';
const PsmComponent = () => {
    return (
        <>

            <div class="row bg-dr text-center" style={{ paddingTop: "100px" }} id="psm">
                <div class="col-12">
                    <h1 class="display-4 mb-4">Empower Your Server Management with <b style={{ backgroundColor: "orange", padding: "10px 15px", color: "black" }}> Personal Server Manager</b></h1>
                </div>
            </div>

            <div className="row bg-dr text-left" style={{ padding: "15px 0px 50px 0px" }}>
                <div className="container">
                    <hr />
                    <p className="lead mb-4">
                        Elevate your server management experience with <b>Personal Server Manager</b>, tailor-made for individual users overseeing personal or home projects. Whether you're a tech enthusiast managing a home server or a hobbyist running pet projects, our WPF application is designed to empower you with seamless remote server administration capabilities.
                    </p>

                    <div className="text-right">
                        <a className="btn btn-info mt-3" href="https://github.com/anton-salata/personal-server-manager" role="button" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z" />
                            </svg>
                            &nbsp; GitHub
                        </a>
                    </div>
                    <hr />
                </div>
            </div>



            <div id="myCarousel1" className="carousel slide bg-dr pb-5" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#myCarousel1" data-slide-to="0" className="active"></li>
                    <li data-target="#myCarousel1" data-slide-to="1"></li>
                </ol>

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row ">
                            <div className="container">
                                <div class="row mb-5">
                                    <div class="col-md-4 lead">

                                        <h3>Intuitive Dashboard</h3>
                                        <p>Stay in control of your servers with a sleek and user-friendly dashboard. Monitor server health, track performance metrics, and receive real-time notifications all in one central location.</p>

                                        <h3>Remote Deployment</h3>
                                        <p>Effortlessly deploy updates, patches, and applications to your servers remotely. Our app simplifies the deployment process, saving you time and reducing the risk of errors.</p>

                                    </div>
                                    <div class="col-md-8">
                                        <img src={PersonalMainImg} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row ">
                            <div className="container">
                                <div class="row mb-5">
                                    <hr></hr>
                                    <div class="col-md-8 lead">
                                        <img src={PersonalServerImg} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                                    </div>
                                    <div class="col-md-4">

                                        <h3>Script Automation</h3>
                                        <p>Automate repetitive tasks and workflows with our built-in script automation feature. Write custom scripts or choose from a library of pre-configured scripts to streamline your operations.</p>

                                        <h3>Secure Access</h3>
                                        <p>Rest easy knowing your server infrastructure is protected with robust security features. Our app prioritizes data integrity and confidentiality, ensuring only authorized personnel have access to critical systems.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <a className="carousel-control-prev" href="#myCarousel1" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#myCarousel1" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>


        </>
    );
}

export default PsmComponent;