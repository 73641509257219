import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Slider from 'react-slick';

const MerchCard = ({ item }) => {
    const [selectedColor, setSelectedColor] = useState(null);

    useEffect(() => {
        if (item) {
            // When product details are available, set the default color and size
            const defaultColor = item.options.find(option => option.name === 'Colors')?.values[0].id;
            setSelectedColor(defaultColor);
        }
    }, [item]);

    // Filter variants based on selected color and size
    const filteredVariants = item.variants.filter(variant => {
        const colorMatch = selectedColor ? variant.options.includes(selectedColor) : true;
        return colorMatch;
    });

    // Get the variant IDs of the filtered variants
    const filteredVariantIds = filteredVariants.map(variant => variant.id);

    // Filter images based on the filtered variant IDs
    const filteredImages = item.images.filter(image =>
        image.variant_ids.some(variantId => filteredVariantIds.includes(variantId))
    );

    // Filter out colors that don't have associated variants
    const availableColors = item.options
        .find(option => option.name === 'Colors')
        .values.filter(color =>
            item.variants.some(variant => variant.options.includes(color.id))
        );

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div style={{ maxWidth: '350px', margin: 'auto' }}>
            {/* Image Rotator */}
            <div style={{ marginBottom: '16px' }}>
                <Slider {...sliderSettings}>
                    {filteredImages.map((image, index) => (
                        <div key={index}>
                            <img src={image.src} alt={item.title} style={{ width: '100%', height: 'auto' }} />
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Title */}
            <Typography variant="h4" gutterBottom>
                {item.title}
            </Typography>

            {/* Color Selectors */}
            <div style={{ display: 'flex', marginBottom: '16px' }}>
                {availableColors.map(color => (
                    <div
                        key={color.id}
                        style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: color.colors[0],
                            margin: '4px',
                            cursor: 'pointer',
                            border: selectedColor === color.id ? '2px solid black' : 'none'
                        }}
                        onClick={() => setSelectedColor(color.id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MerchCard;
