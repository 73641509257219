import React from 'react';
import WebLogo from '../../images/Logoaiready.png'
import EightBallLogo from '../../images/EightBallLogo.png'

function EightBallPage() {
    return (
        <div>
            {/* <!-- Navigation -->*/}
            <nav class="navbar navbar-expand-lg navbar-light bg-light bg-yellow">
                <div class="container">
                    <a class="navbar-brand text-white" href="/"><img src={WebLogo} alt="..." style={{ height: "25px" }} /></a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item">
                                <a class="nav-link nav-item-custom" href="/">Home</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link  nav-item-custom" href="/bots/eightball">8 Ball Bot</a>
                            </li>                           
                            <li class="nav-item">
                                <a class="nav-link nav-item-custom" href="/games">Games</a>
                            </li>  
                        </ul>
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <a class="nav-link" href="#"><i class="fab fa-facebook"></i></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#"><i class="fab fa-twitter"></i></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#"><i class="fab fa-instagram"></i></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#"><i class="fas fa-user"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div class="container text-center">
                <div class="row">
                    <div class="col-md-6">
                        <img src={EightBallLogo} alt="..." style={{ height: "405px" }} />
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <div class="text-center">
                            <h2>🔮 Magic 8 Ball Bot  🔮</h2>
                            <p>Welcome to the Magic 8 Ball Bot! Get answers to your burning questions and uncover the mysteries of the universe with a shake of the virtual Magic 8 Ball.</p>
                            <p> <a style={{ color: 'white' }} target="_blank" href="https://t.me/Eigh_ball_and_good_luck_bot"><i style={{ fontSize: "2rem" }} class="bi bi-telegram"></i> Link to Telegram Bot</a></p>
                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Title and Text Section -->*/}
            <div class="container text-center">    
                <hr style={{ color: 'white' }} />
                <h2>🤔 How It Works</h2>
                <p>
                    Simply send your question to the bot.<br/>
                    Shake the virtual Magic 8 Ball.<br />
                    Receive insightful and enigmatic answers to guide you on your journey.<br />
                </p>
                <br />
                <h2>🌟 Features</h2>
                <p>
                    <b>  Instant Responses:</b> Get immediate answers to your questions.<br />
                    <b>   Randomized Answers:</b>  Each response is randomly generated for an element of surprise.<br />
                    <b>   Fun and Entertaining: </b> Engage in lighthearted divination and amusement with friends.<br />
                </p>
                <br />
                <h2>🔥 Why Choose Magic 8 Ball Bot?</h2>
                <p>
                    Perfect for decision-making dilemmas<br />
                    Offers a touch of magic and whimsy to your chats<br />
                    Provides entertainment and amusement for all ages<br />
                </p>
                <br />
                <h2> 🚀 How to Get Started</h2>
                <p>
                    Go to <a style={{ color: 'white' }} target="_blank" href="https://t.me/Eigh_ball_and_good_luck_bot"><i class="bi bi-telegram"></i>&nbsp;Magic 8 Ball Bot</a> and ust start a chat with the Magic 8 Ball Bot, ask your question, and let the mystical powers of the 8 Ball reveal the answer!
                </p>
                <hr style={{ color: 'white' }} />
            </div>

            {/* <!-- Footer Section -->*/}
            <footer class="footer">
                <div class="container text-center">
                    <p>&copy; 2023 Your Website</p>
                </div>
            </footer>



        </div>
    );
}

export default EightBallPage;
