import React, { useState } from 'react';
import './ExpandableTag.css'; // Make sure to adjust the path if needed

const ExpandableTag = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`expandable-tag ${isExpanded ? 'expanded' : ''}`}>
            <div className="tag-content">
                <i className="fas fa-music" style={{ fontSize: '12px', marginLeft: '15px', marginRight: '7px' }} onClick={toggleExpand}></i>
                {isExpanded && (
                    <div className="inner-content">
                        {children}
                        <i className="fas fa-close" style={{ fontSize: '15px', marginLeft: '8px' }} onClick={toggleExpand}></i>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExpandableTag;
