import logo from './logo.svg';
import './App.css';
import { Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home_page';
import EightBallPage from './pages/bots/eight_ball_page';
import GamesCollectionPage from './pages/games/games_collection_page';
import PlayGamePage from './pages/games/play_game';
import CryptorPage from './pages/cryptor_page';
import ContactUsPage from './pages/contactus_page';
import MerchPage from './pages/merch_page';
function App() {
    return (

        <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/bots/eightball" element={<EightBallPage />} />
            <Route path="/games" element={<GamesCollectionPage />} />
            <Route path="/cryptor" element={<CryptorPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/merch" element={<MerchPage />} />
        </Routes>

    );
}

export default App;
