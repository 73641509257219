import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Button } from '@mui/material';
import ProductCard from '../../compoonents/ProductCard';
import EnC from '../../images/EnryptorCLogo.png';
import lgtwo from '../../images/lgtwo.png';
import ovpn from '../../images/ovpn.png';
import lwood from '../../images/lwood.png';
import tgl from '../../images/tgl.png';
import smicon from '../../images/sm_icon.png';
import gllogo from '../../images/gllogo.png';

// Products array
const products = [
    {
        image: EnC,
        title: 'Encryptor C + Bot',
        description: 'Client-side encryption and decryption tool. Supports text and files',
        githubLink: 'https://github.com/anton-salata/reactjs-encryptor-component',
        infoButton: { title: 'More', link: '#cryptorc' },
        primaryButton: { title: 'Web Version', link: '/cryptor' },
        botLink: 'https://t.me/encryptor_c_bot'
    },
    {
        image: ovpn,
        title: 'Free Open VPN Bot',
        description: 'Free Open VPN Telegram Bot with no restrictions for legal use via OpenVPN technology',
        infoButton: { title: 'More', link: '#tgvpn' },
        botLink: 'https://t.me/encryptor_c_bot'
    },
    {
        image: lwood,
        title: 'HTTP Log Viewer',
        description: 'Software solution designed to enhance the process of tracking HTTP requests and responses',
        githubLink: 'https://github.com/anton-salata/http-log-viewer',
        infoButton: { title: 'More', link: '#httpLogViewer' }
    },
    {
        image: tgl,
        title: 'Telegram Bot Boilerplate',
        description: 'This repository serves as a boilerplate for creating Telegram bots using .NET Core',
        githubLink: 'https://github.com/anton-salata/tg-bot-boilerplate',
        infoButton: { title: 'More', link: '#tgplate' },
    },
    {
        image: smicon,
        title: 'Personal Server Manager',
        description: 'Tailor-made for individual users overseeing personal or home projects and deployments',
        githubLink: 'https://github.com/product2',
        infoButton: { title: 'More', link: '#psm' },
    },
    {
        image: gllogo,
        title: '8 Ball and Good Luck',
        description: 'Looking for some guidance or a motivational boost? Feel free to ask for a bit of help!',
        infoButton: { title: 'More', link: '#goodluck' },
        botLink: 'https://t.me/eight_ball_and_good_luck_bot'
    },
    {
        image: lgtwo,
        title: 'Techie Buddy AI',
        description: 'Go-to tool for answering questions about software, tools, or programming languages',
        githubLink: 'https://github.com/anton-salata/techie-buddy-ai',
        infoButton: { title: 'More', link: '#techieBuddyAI' },
    },
    // Add more products if necessary
];


//const carouselSettings = {
//    dots: true,
//    infinite: true,
//    speed: 500,
//    slidesToShow: 3,
//    slidesToScroll: 1,
//    prevArrow: <div className="custom-prev-arrow">�</div>,
//    nextArrow: <div className="custom-next-arrow">�</div>
//};

const carouselSettings = {
    dots: true, // Show dots
    infinite: true, // Infinite loop
    speed: 1500, // Transition speed
    slidesToShow: 3, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Time between slides in milliseconds
    pauseOnHover: true, // Pause autoplay on hover
    prevArrow: <div className="custom-prev-arrow">�</div>,
    nextArrow: <div className="custom-next-arrow">�</div>
};

const ProductList = () => {
    return (
        <Box sx={{ padding: 4 }} className="bg-yellow">
            <Slider {...carouselSettings}>
                {products.map((product, index) => (
                    <div key={index}>
                        <ProductCard {...product} />
                    </div>
                ))}
            </Slider>
        </Box>
    );
};

export default ProductList;
