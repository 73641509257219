import WebLogo from '../../images/LogoEl.png';
import WebLogo3 from '../../images/3trexn.png';
import BootstrapModal from '../../compoonents/BootstrapModal';
import React, { useState } from 'react';

const Navigation = () => {
    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);




    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light bg-yellow">
                <a className="navbar-brand text-white" href="/">
                    <img src={WebLogo} alt="Web Logo" style={{ height: "85px" }} />
                    <img src={WebLogo3} alt="Web Logo 3" style={{ height: "85px" }} />
                    <p style={{ marginBottom: "-7px", fontSize: "small" }}>&#9775; Powered by Anton Salata &#9734;</p>
                </a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link nav-item-custom" href="/">Home</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle nav-item-custom" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-code-branch"></i>&nbsp;Open Source Solutions
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item dropdown-item-custom" href="#techieBuddyAI">Techie Buddy AI (zzzcode.ai)</a>
                                <a className="dropdown-item dropdown-item-custom" href="#httpLogViewer">HTTP Log Viewer</a>
                                <a className="dropdown-item dropdown-item-custom" href="#tgplate">Telegram <i className="fab fa-telegram"></i> Bot Boilerplate</a>
                                <a className="dropdown-item dropdown-item-custom" href="#psm">Personal Server Manager (WIP)</a>
                                <a className="dropdown-item dropdown-item-custom" href="#cryptorc">Encryptor C</a>
                                <a className="dropdown-item dropdown-item-custom" href="#printman">Printify Manager</a>
                 {/*               <a className="dropdown-item dropdown-item-custom" href="/games">Data Adapt (WIP)</a>*/}
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle nav-item-custom" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fab fa-telegram"></i>&nbsp;Telegram Bots
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item dropdown-item-custom" href="#goodluck">8 Ball and Good Luck</a>
                                <a className="dropdown-item dropdown-item-custom" href="#tgvpn">Free Open VPN</a>
                                <a className="dropdown-item dropdown-item-custom" href="#cryptorc">Encryptor/Decryptor</a>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle nav-item-custom" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Web
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <a className="dropdown-item dropdown-item-custom" href="/games">Games</a>
                                <a className="dropdown-item dropdown-item-custom" href="/cryptor">Encryptor/Decryptor</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link nav-item-custom" href="/merch">Merch</a>
                        </li>
                        {/* Add Search here */}
                        {/* <div className="nav-item-group">
                        <ul className="navbar-nav">                              
                            <li className="nav-item">
                                <a className="nav-link nav-item-custom" href="/games">Contact Us</a>
                            </li>
                        </ul>
                    </div> */}
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link nav-item-custom" href="#" onClick={handleShow}><i className="fas fa-user fa-2x"></i></a>
                        </li>
                    </ul>
                </div>
            </nav>

            {showModal && (
                <BootstrapModal
                    title="Modal Title"
                    bodyContent={<h5 style={{ color: 'black' }}>Forum is coming soon, for now thre is no need for account</h5>}
                    buttons={[
                        <button key="close" type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClose}>Ok</button>,
                       /* <button key="save" type="button" className="btn btn-primary" onClick={() => alert('Action!')}>Save changes</button>*/
                    ]}
                    onClose={handleClose}
                />
            )}

        </>
    );
}

export default Navigation;
