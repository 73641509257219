import React from 'react';
import WebLogo from '../../images/Logoaiready.png'
import gamesData from './games_list.json';
import { useParams } from 'react-router-dom';
import he from 'he';
import Navigation from '../common/navigation';
import Footer from '../common/footer';

function PlayGamePage() {
    const { title } = useParams();

    const gameInfo = gamesData.filter(item => item.Title === title)[0];
    const frameCode = he.decode(gameInfo.FrameCode);

    return (
        <>

            <Navigation />

            <div class="container text-center" style={{ color: 'black' }}>
                <h3 className="mt-3">{gameInfo.Title}</h3>

                <h5 className="mt-5">{gameInfo.Description}</h5>

                <h5 className="mt-5">{gameInfo.Instructions}</h5>

                <div
                    dangerouslySetInnerHTML={{ __html: frameCode }}
                />
            </div>

          


            <Footer />


        </>
    );
}

export default PlayGamePage;
