import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';

const ProductCard = ({ image, title, description, githubLink, infoButton, primaryButton, botLink }) => {
    return (
        <Card className="mb-4 bg-ey mr-1 ml-1">
            <Row noGutters>
                <Col md={4} className="d-flex align-items-center justify-content-center">
                    <Card.Img src={image} style={{ marginLeft: "50px" }} />
                </Col>
                <Col md={8}>
                    <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text>{description}</Card.Text>
                        <div className="d-flex justify-content-end">
                            {botLink && (
                                <Button variant="primary" target="_blank" href={botLink} className="mr-2 btn-sm">
                                    <i className="fa-brands fa-telegram"></i>                                                                  
                                </Button>
                            )}
                            {githubLink && (
                                <Button variant="success" target="_blank" href={githubLink} className="mr-2 btn-sm">
                                    <svg style={{ marginTop: "-3px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z" />
                                    </svg>                                    
                                </Button>
                            )}
                            {infoButton && infoButton.link && (
                                <Button variant="warning" href={infoButton.link} className="mr-2 btn-sm">{infoButton.title}</Button>
                            )}
                            {primaryButton && primaryButton.link && (
                                <Button variant="primary" href={primaryButton.link} target="_blank" className="btn-sm">{primaryButton.title}</Button>
                            )}
                        </div>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
};

export default ProductCard;
