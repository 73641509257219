import ui1 from '../../images/promo/prntf1.png';
import ui2 from '../../images/promo/prntf2.png';

const PrintmanComponent = () => {
    return (
        <>

            <div class="row text-center" style={{ paddingTop: "100px", color: "black" }} id="printman">
                <div class="col-12">
                    <h1 class="display-4 mb-4"><b style={{ backgroundColor: "#1e90ff", padding: "10px 15px", color: "white" }}>Printify Manager</b> manage your products via custom API integration</h1>
                </div>
            </div>

            <div className="row text-left" style={{ padding: "15px 0px 50px 0px", color: "black" }}>
                <div className="container">
                    <hr />
                    <p className="lead mb-4">
                        <b>Printify Manager</b> is a comprehensive tool designed for managing your Printify products and shops via custom API integration. This project provides a user-friendly interface to interact with Printify's API, offering functionality to view, manage, and publish products.
                    </p>

                    <div className="text-right">

                        <a className="btn btn-info mt-3" href="https://github.com/anton-salata/printify-manager" role="button" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z" />
                            </svg>
                            &nbsp; GitHub
                        </a>
                    </div>
                    <hr />
                </div>
            </div>



            <div id="myCarousel3" className="carousel slide pb-5 carousel-dark" data-ride="carousel" style={{ color: 'black' }}>
                <ol className="carousel-indicators">
                    <li data-target="#myCarousel3" data-slide-to="0" className="active invert"></li>
                    <li data-target="#myCarousel" data-slide-to="1" className="invert"></li>
                </ol>

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row ">
                            <div className="container">
                                <div class="row mb-5">
                                    <div class="col-md-6 lead">

                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <h5 class="mb-1">View Shop Details</h5>
                                                <p class="mb-1">Retrieve and display information about your Printify shops.</p>
                                            </li>

                                            <li class="list-group-item">
                                                <h5 class="mb-1">Product Management</h5>
                                                <p class="mb-1">Fetch and manage products in your shops, including details, images, and variants.</p>
                                            </li>


                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <img src={ui1} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row ">
                            <div className="container">
                                <div class="row mb-5">
                                    <hr></hr>
                                    <div class="col-md-6 lead">
                                        <img src={ui2} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                                    </div>
                                    <div class="col-md-6 lead">
                                        <ul class="list-group">

                                            <li class="list-group-item">
                                                <h5 class="mb-1">Publish Products</h5>
                                                <p class="mb-1">Set products as published through the API.</p>
                                            </li>

                                            <li class="list-group-item">
                                                <h5 class="mb-1">Detailed Product View</h5>
                                                <p class="mb-1">Navigate to detailed views of individual products, including their images and descriptions.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>

                <a className="carousel-control-prev" href="#myCarousel3" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon invert" aria-hidden="true" style={{ filter: "invert(100%)" }}></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#myCarousel3" role="button" data-slide="next">
                    <span className="carousel-control-next-icon invert" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>


        </>
    );
}

export default PrintmanComponent;