import React from 'react';
import StampLogo from '../../images/dlrlogo.png';
import mutantsMOB3 from '../../images/TigerLogo.jpg';
import mutantsMOB3Cover from '../../images/TigerLogoCover.png';
import mutantsMOB3Cover1 from '../../images/TigerLogoCover1.png';
import AudioPlayer from '../../compoonents/AudioPlayer';
import ExpandableTag from '../../compoonents/ExpandableTag';
import Ticker from '../../compoonents/Ticker.js';

const Lead = () => {
    return (
        <>
            <div className="row">
                <div className="col" style={{ position: "relative" }}>
                    <div className="image-container">
                        <img src={mutantsMOB3} style={{ width: "100%", borderTop: '5px solid black' }} alt="Mutants Image" />
                        <img src={mutantsMOB3Cover1} style={{ width: "100%", borderTop: '5px solid black', position: "absolute", top: 0, left: 0 }} alt="Mutants Cover Image" />

                        <img src={mutantsMOB3Cover} className="shaking-image" style={{ width: "100%", borderTop: '5px solid black', position: "absolute", top: 0, left: 0 }} alt="Mutants Cover Image" />
                    </div>
                    <img className="sound-visualization" src={StampLogo} style={{ position: "absolute", top: "-25px", right: "0", width: "10%", marginRight: "18%", transform: 'rotate(12deg)' }} alt="Stamp Logo" />

                    <div className="tags-container">
                        <a className="tag-link" href="https://github.com/anton-salata" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github fa-lg"></i>
                        </a>
                        <a className="tag-link" href="https://www.instagram.com/anton_s8888" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram fa-lg"></i>
                        </a>
                        <a className="tag-link" href="https://t.me/next_century" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-telegram fa-lg"></i>
                        </a>
                        <a className="tag-link" href="https://www.linkedin.com/in/anton-salata/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-linkedin fa-lg"></i>
                        </a>
                    </div>

                    <div className="contact-us-container">
                        <a className="contact-link" href="/contact">
                            Contact Us
                        </a>
                    </div>

                    <ExpandableTag>
                        <AudioPlayer />
                    </ExpandableTag>
                </div>
            </div>


            <Ticker />
        </>
    );
}

export default Lead;
