import React, { useState, useEffect } from 'react';
import Footer from './common/footer';
import Navigation from './common/navigation';
import MerchCard from '../compoonents/MerchCard';
import merchData from '../data/merch.json'; // Import your JSON data

const MerchPage = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Load products from JSON data
        if (merchData && merchData.data) {
            setProducts(merchData.data);
        }
    }, []);

    return (
        <>
            <Navigation />
            <div style={{ padding: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {products.map(product => (
                    <div key={product.id} style={{ margin: '20px' }}>
                        <MerchCard item={product} />
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
};

export default MerchPage;
