import React from 'react';
import WebLogo from '../../images/Logoaiready.png';
import { Link } from 'react-router-dom';
import gamesData from './games_list.json';
import Navigation from '../common/navigation';
import Footer from '../common/footer';

function GamesCollectionPage() {
    return (

        <>
            <Navigation />


            <div class="container" style={{color:'black'}}>
                <h1 className="mt-5 mb-5">Free Games</h1>
            </div>

            <div className="container" style={{ color: 'black' }}>
                <div className="row">
                    {gamesData.map((item, index) => (
                        <div className="col-md-3 mb-3" key={index}>
                            <div className="card h-100 bg-transparent">
                                <img src={item.Image} className="card-img-top" alt={item.Title} />
                                <div className="card-body">
                                    <h5 className="card-title">{item.Title}</h5>
                                    <p className="card-text">{item.Description}</p>
                                    <Link to={`/games/play/${encodeURIComponent(item.Title)}`} className="btn btn-primary mr-2" target="_blank" rel="noopener noreferrer">Play Now</Link>

                                    {/*         <a href={item.RevenueShare} className="btn btn-primary mr-2" target="_blank" rel="noopener noreferrer">Play Now</a>*/}
                                    {/*<a href={item.Download} className="btn btn-secondary" target="_blank" rel="noopener noreferrer">Details</a>*/}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </>
    );
}

export default GamesCollectionPage;
