import React, { useState } from 'react';

const Footer = () => {
    return (
        <>

            <footer class="footer bg-yellow text-white py-4">
                <div class="container text-center">
                    <div class="row">
                        <div class="col-md-12 mb-3 bg-light bg-yellow">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    <a class="nav-link nav-item-custom" href="https://github.com/anton-salata" target="_blank">
                                        <i class="fab fa-github fa-2x"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="nav-link nav-item-custom" href="https://www.instagram.com/anton_s8888" target="_blank">
                                        <i class="fab fa-instagram fa-2x"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="nav-link nav-item-custom" href="https://t.me/next_century" target="_blank">
                                        <i class="fab fa-telegram fa-2x"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="nav-link nav-item-custom" href="https://www.linkedin.com/in/anton-salata/" target="_blank">
                                        <i class="fab fa-linkedin fa-2x"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a className="nav-link nav-item-custom" href="/contact">Contact Us</a>
                                </li>


                            </ul>
                        </div>
                        <div class="col-md-12">
                            <p>&copy; 2024 Elysium Mob</p>
                        </div>
                    </div>
                </div>
            </footer>


        </>
    );
}

export default Footer;
