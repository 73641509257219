import HttpV1 from '../../images/promo/hlv1.png';
import HttpV2 from '../../images/promo/MultipartView.png';
import HttpV3 from '../../images/promo/jhviewe.png';

const HttpLogViwerComponent = () => {
    return (
        <>
            <div class="row bg-dr text-center" style={{ paddingTop: "100px" }} id="httpLogViewer"> 
                <div class="col-12">
                    <h1 class="display-4 mb-4">Streamline tracking of HTTP traffic with <b style={{ backgroundColor: "orange", padding: "10px 15px", color: "black" }}> HTTP Log Viewer</b></h1>
                </div>
            </div>


            <div className="row bg-dr text-left" style={{ padding: "15px 0px 50px 0px" }}>
                <div className="container">
                    <hr />
                    <p className="lead mb-4">
                        Our <b>HTTP Request Log Viewer</b> is a software solution designed to simplify and enhance the process of tracking and analyzing HTTP requests and responses. With seamless integration for .NET Core applications, it captures every detail of your HTTPClient interactions, providing you with a comprehensive and intuitive interface for monitoring your network traffic.
                    </p>
                    <div className="text-right">
                        <a className="btn btn-info mt-3" href="https://github.com/anton-salata/http-log-viewer" role="button" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z" />
                            </svg>
                            &nbsp; GitHub
                        </a>
                    </div>
                    <hr />
                </div>
            </div>



            <div id="myCarousel" className="carousel slide bg-dr" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                    <li data-target="#myCarousel" data-slide-to="2"></li>
                </ol>

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="row ">
                            <div className="container">
                                <div className="row  mb-5">
                                    <div className="col-md-8 lead col-lg-7">
                                        <img src={HttpV1} alt="HttpV1" style={{ width: "100%" }} />
                                    </div>
                                    <div className="col-md-4 col-lg-5">
                                        <h3>User-friendly UI</h3>
                                        <p>
                                            The software features a user-friendly UI that enables you to view and inspect headers, parsed or raw JSON content for both requests and responses, making it an invaluable tool for developers, QA engineers, and network administration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row ">
                            <div className="container">
                                <div className="row mb-5 ">
                                    <div className="col-md-4 lead col-lg-4">
                                        <h3>JSON Parsing</h3>
                                        <p>View parsed or raw JSON content for both requests and responses.</p>
                                        <h3>Multipart Data Handling</h3>
                                        <p>Ability to view and download multipart data with ease. This feature allows you to see the different parts of a multipart request, including file uploads, and download individual files directly from the interface.</p>
                                    </div>
                                    <div className="col-md-8 col-lg-8">
                                        <img src={HttpV2} alt="HttpV2" style={{ width: "100%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row ">
                            <div className="container">
                                <div className="row mb-5">
                                    <div className="col-md-4 lead col-lg-6">
                                        <h3>Detailed Request and Response Logging</h3>
                                        <p>Capture and view all HTTP requests and responses made by your application.</p>
                                        <h3>Header Inspection</h3>
                                        <p>Easily inspect request and response headers. JSON Parsing: View parsed or raw JSON content for both requests and responses.</p>
                                    </div>
                                    <div className="col-md-8 col-lg-6">
                                        <img src={HttpV3} alt="HttpV3" style={{ width: "100%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>



        </>
    );
}

export default HttpLogViwerComponent;