import BotVImg from '../../images/promo/photoportrait.png';

const TgVpnBotComponent = () => {
    return (
        <>

            <div class="row text-center" style={{ padding: 45, color: 'black' }} id="tgvpn">
                <div class="col-12">
                    <h1 class="display-4 mb-4"><b style={{ backgroundColor: "#1e90ff", padding: "10px 15px", color: 'white' }}><i class="fa-brands fa-telegram"></i> Free Open VPN Bot</b> with no restrictions for legal use</h1>
                </div>
            </div>


            <div class="row" style={{ color: 'black' }}>
                <div class="container">
                    <div class="row mb-5">
                        <div class="col-md-4 lead">
                            <img src={BotVImg} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                        </div>
                        <div class="col-md-8 d-flex flex-column">
                            <div class="flex-grow-1">
                                <hr />
                                <p>Stay anonymous on the Internet, have a secure connection, get a complete freedom and access to the region-locked online resources</p>
                                <p>All these features are available via OpenVPN technology and our service</p>
                                <p>Daily updates of a servers list, huge variety of servers in different countries, no restrictions, and all of that is absolutely free for the users of our bot</p>
                                <hr />

                            </div>

                            <p style={{ backgroundColor: 'antiquewhite', padding: "10px", marginBottom: "50px" }} class="mt-auto">Warning! Any illegal use (e.g. hacks, scams, spams, etc.) involving our technology and/or services will be immediately reported to the law enforcement authorities.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TgVpnBotComponent;