import gl from '../../images/promo/gl.png';
import eb from '../../images/promo/8b.jpg';


const GoodLuckComponent = () => {
    return (
        <>

            <div class="row text-center bg-dr" style={{ padding: 45, color: 'black' }} id="goodluck">
                <div class="col-12">
                    <h1 class="display-4 mb-4"><b style={{ backgroundColor: "orange", padding: "10px 15px", color: 'black' }}><i class="fa-brands fa-telegram"></i> 8 Ball and Good Luck</b> your go-to source for life advice</h1>
                </div>
            </div>


            <div className="row text-left  bg-dr" style={{ padding: "15px 0px 50px 0px", color: "black" }}>
                <div className="container">
                    <hr />
                    <p className="lead mb-4">
                        Welcome to 8 Ball and Good Luck! 🌟 Your go-to source for life advice and inspiring quotes from famous personalities. Need a bit of guidance or a motivational boost? Just ask!
                    </p>
                    <div className="text-right">
                        <a className="btn btn-primary mt-3 mr-3" href="https://t.me/eight_ball_and_good_luck_bot" role="button" target="_blank">
                            <i class="fa-brands fa-telegram"></i> Bot
                        </a>
                    </div>
                    <hr />
                </div>
            </div>

            <div class="row  bg-dr" style={{ color: 'black' }}>
                <div class="container">
                    <div class="row mb-5">
                        <div class="col-md-7 d-flex flex-column">
                            <div class="flex-grow-1 lead">
                                <p>
                                    8 Ball and Good Luck is your friendly assistant for life advice and quotes from the greats! Whether you’re seeking wisdom or a daily dose of inspiration, our bot is here to provide you with thoughtful advice and uplifting quotes. Just type in your question or request, and let the magic happen!
                                </p>
                                <div className="card mx-auto mt-5" style={{ padding: "50px", color: "black" }}>
                                    <img src={eb} className="card-image" alt="Card" style={{ width: "240px", margin: "10px auto" }} />
                                    <div className="card-content text-center">
                                        <h2 className="card-title">
                                            <p><i className="fa-brands fa-telegram"></i> Telegram Bot</p>
                                        </h2>
                                        <p>Need a bit of guidance or a motivational boost? Just ask!</p>
                                        <a href="https://t.me/encryptor_c_bot" className="card-link" target="_blank" rel="noopener noreferrer">@eight_ball_and_good_luck_bot</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 lead">
                            <img src={gl} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GoodLuckComponent;