import TechieAI from '../../images/promo/tbaiui1.png';

const TechieBuddyAIComponent = () => {
    return (
        <>
            <div className="row bg-dr text-center" style={{ padding: 45, color: 'white' }} id="techieBuddyAI">
                <div className="col-12">
                    <h1 className="display-4 mb-4">
                        <b style={{ backgroundColor: "orange", padding: "10px 15px", color: 'black' }}>
                            Techie Buddy AI
                        </b>
                        &nbsp;is your go-to tool for answering questions about software
                    </h1>
                </div>
            </div>


            <div className="row bg-dr text-left" style={{ padding: "15px 0px 50px 0px" }}>
                <div className="container">
                    <hr />
                    <p className="lead mb-4">
                        <b>Techie Buddy AI</b> is your go-to tool for answering questions about software, tools, or programming languages. Powered by <a href="https://zzzcode.ai/">ZZZcode AI</a>, a comprehensive platform for all aspects of coding.
                    </p>

                    <div className="text-right">
                        <a className="btn btn-info mt-3" href="https://github.com/anton-salata/techie-buddy-ai" role="button" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z" />
                            </svg>
                            &nbsp; GitHub
                        </a>
                    </div>
                    <hr />
                </div>
            </div>




            <div className="row bg-dr" style={{ color: 'white' }}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-5 lead">
                            <img
                                src={TechieAI}
                                alt="Techie Buddy AI"
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="col-md-7 d-flex flex-column lead">
                            <div className="flex-grow-1">
                                <h2>Features</h2>
                                <ul>
                                    <li>Instant answers to questions about software, tools, or programming languages.</li>
                                    <li>Natural language understanding for intuitive interaction.</li>
                                    <li>Fast and reliable responses powered by machine learning.</li>
                                </ul>

                                <h2>How it Works</h2>
                                <p>
                                    <strong>Techie Buddy AI</strong> leverages the capabilities of <a href="https://zzzcode.ai/">ZZZcode AI</a> to understand and respond to queries related to software, tools, and programming languages. It utilizes cutting-edge natural language processing models to analyze and generate responses based on the input questions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TechieBuddyAIComponent;