import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';

const BootstrapModal = ({ title, bodyContent, buttons, onClose }) => {
    const modalRef = useRef(null);
    const modalInstanceRef = useRef(null);

    useEffect(() => {
        const modalElement = modalRef.current;

        if (modalElement) {
            modalInstanceRef.current = new Modal(modalElement, {
                backdrop: true, // Keep backdrop separate
                keyboard: false
            });
        }

        return () => {
            if (modalInstanceRef.current) {
                modalInstanceRef.current.hide();
                // Ensure backdrop is also removed when modal is hidden
                const backdrop = document.querySelector('.modal-backdrop');
                if (backdrop) {
                    backdrop.parentNode.removeChild(backdrop);
                }
            }
        };
    }, []);

    useEffect(() => {
        if (modalInstanceRef.current) {
            modalInstanceRef.current.toggle();
        }
    }, [title, bodyContent, buttons]);

    return (
        <div className="modal" tabIndex="-1" role="dialog" ref={modalRef}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {/*<div className="modal-header">*/}
                    {/*    <h5 className="modal-title">{title}</h5>*/}
                    {/*    <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>*/}
                    {/*</div>*/}
                    <div className="modal-body">
                        {bodyContent}
                    </div>
                    <div className="modal-footer">
                        {buttons.length > 0
                            ? buttons.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))
                            : <button type="button" className="btn btn-primary" onClick={onClose}>Ok</button>}
                    </div>
                </div>
            </div>
        </div>
    );
};

BootstrapModal.propTypes = {
    title: PropTypes.string.isRequired,
    bodyContent: PropTypes.node.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.node),
    onClose: PropTypes.func.isRequired,
};

BootstrapModal.defaultProps = {
    buttons: [],
};

export default BootstrapModal;
