import BotVImg from '../../images/promo/bp1.png';

const TgBotPlateComponent = () => {
    return (
        <>

            <div class="row text-center" style={{ padding: 45, color: 'black' }} id="tgplate">
                <div class="col-12">
                    <h1 class="display-4 mb-4"><b style={{ backgroundColor: "#1e90ff", padding: "10px 15px", color: 'white' }}>Telegram <i class="fa-brands fa-telegram"></i> Bot Boilerplate</b> to boost your bots development</h1>
                </div>
            </div>


            <div className="row text-left" style={{ padding: "15px 0px 50px 0px", color: "black" }}>
                <div className="container">
                    <hr />
                    <p className="lead mb-4">
                        This repository serves as a boilerplate for creating Telegram bots using.NET Core.It includes examples of how to send text messages, photos, and files.Additionally, it features a built-in HTTP request/response tracker to log all HTTP interactions made by the bot.
                    </p>
                    <div className="text-right">
                        <a className="btn btn-info mt-3" href="https://github.com/anton-salata/tg-bot-boilerplate" role="button" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z" />
                            </svg>
                            &nbsp; GitHub
                        </a>
                    </div>
                    <hr />
                </div>
            </div>

            <div class="row" style={{ color: 'black' }}>
                <div class="container">
                    <div class="row mb-5">
                        <div class="col-md-8 d-flex flex-column">
                            <div class="flex-grow-1 lead">

                                <div>
                                    <h3>Sending Messages:</h3>
                                    <ul>
                                        <li>Example of sending text messages to Telegram users.</li>
                                        <li>Example of sending photos as responses.</li>
                                        <li>Example of sending files such as documents or media.</li>
                                    </ul>
                                </div>

                                <div className="mt-5">
                                    <h3>HTTP Request/Response Tracker:</h3>
                                    <ul>
                                        <li>Captures and logs all incoming and outgoing HTTP requests/responses.</li>
                                        <li>Utilizes MongoDB or another database to store logs securely.</li>
                                    </ul>
                                </div>

                            </div>

                            <p style={{ backgroundColor: 'antiquewhite', padding: "10px", marginBottom: "50px" }} class="mt-auto">
                                <h5>
                                    HTTP Log Viewer for Analyzing Captured Logs
                                </h5>
                                For a convenient and user-friendly way to analyze HTTP request and response logs captured using this Telegram bot boilerplate solution, check out the <a href="https://github.com/anton-salata/http-log-viewer" target="_blank">HTTP Log Viewer repository</a>. It provides a comprehensive web-based interface to visualize, search, and interpret the captured logs effortlessly.
                            </p>
                        </div>
                        <div class="col-md-4 lead">
                            <img src={BotVImg} style={{ width: "100%", boxShadow: "10px 10px 8px 0px dark-gray" }}></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TgBotPlateComponent;